import {
    Component,
    Inject,
    OnInit,
    ViewChild,
    ViewChildren,
    Input,
    ElementRef,
    QueryList,
    ChangeDetectorRef,
    HostListener,
    OnChanges,
    SimpleChanges,
    DoCheck,
    Renderer2,
    ChangeDetectionStrategy
} from '@angular/core';
import {
    CalendarResourcePlanningService
} from "@/angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.service";
import * as moment from "moment";
import {BaseFieldsComponent} from "@/angular-blocworx/components/fields/BaseFieldsComponent";
import {
    AddNewRecordComponent
} from "@/angular-blocworx/components/fields/calendar-resource-planning/add-new-record.component";

import {
    EditRecordComponent
} from "@/angular-blocworx/components/fields/calendar-resource-planning/edit-record.component";

import {
    ShowCountedDataPopup
} from "@/angular-blocworx/components/fields/calendar-resource-planning/show-counted-data-popup.component"
import {MatDialog} from "@angular/material/dialog";
import {OverlayContainer} from '@angular/cdk/overlay';

@Component({
    selector: 'calendar-resource-planning-in-form',
    templateUrl: 'angular-blocworx/components/fields/calendar-resource-planning/templates/in-form.html',
    styleUrls: ['angular-blocworx/components/fields/calendar-resource-planning/calendar-resource-planning.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CalendarResourcePlanningInForm extends BaseFieldsComponent implements OnInit, DoCheck {

    @Input() field;
    @Input() scanStation;
    @Input() main;
    date: Date = new Date();
    lastDay: number;
    prevLastDay: number;
    firstDayIndex: number;
    lastDayIndex: number;
    nextDays: number;
    months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    dayNames: string[] = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']
    hours = Array.from({length: 24}, (_, i) => i);
    days: any[] = [];
    private _currentDay = moment().locale('en-gb');
    private _currentWeek: moment.Moment = moment().locale('en-gb').startOf('week');
    dateForCalendarData: moment.Moment = moment().locale('en-gb').startOf('day');
    calendarDataItemStartDates: object;
    calendarDataItemContinuedDates: object;
    calendarItemCounts: object;
    linkedBlocFieldNames: object = {};
    linkedBlocFieldSlugs: object = {};
    leftHandDataListFieldSlug: string;
    endDateHasTimeFormats: object = {};
    startDateHasTimeFormats: object = {};
    calendarUpdated: number;
    calendarHighestOffsets: Array<string>;
    dataTransferObject: any;
    @ViewChild('zoomableDiv') zoomableDiv: ElementRef;
    private touchStartDistance: number;
    private scale: number = 1;
    private lastTouchX: number;
    private isPinching: boolean = false;
    private lastTouchY: number;
    currentScale = 1;
    currentTranslateX = 0;
    currentTranslateY = 0;
    private isDragging: boolean = false;
    private openDialogsCount = 0;
    originalCalendarResourcePlanningViewType: string = ''
    itemHeight: number = 29


    private previousValue: any;
    fullScreen: object;


    /**
     * Initializes the component.
     *
     * @returns {void}
     */
    ngOnInit(): void {
        this.calendarService.registerComponentInstance(this.field.id, this);
        this.fullScreen = {};
        this.fullScreen[this.field.id] = false;
        this.initialiseCurrentDay();
        this.initialiseCurrentWeek();
        this.initialiseCurrentMonth();

    }

    /**
     * We are removing the instance of this component
     */
    ngOnDestroy() {
        // Deregister this component instance when destroyed
        this.calendarService.deregisterComponentInstance(this.field.id);
    }

    private initialiseCurrentDay() {
        const savedDay = this.calendarService.getCurrentDay();
        if (savedDay && savedDay.isValid()) {
            this._currentDay = savedDay;
        }
        this.saveCurrentDay();
    }

    get currentDay() {
        return this._currentDay;
    }

    set currentDay(value) {
        if (!this._currentDay.isSame(value, 'day')) {
            this._currentDay = value;
            this.saveCurrentDay();
        }
    }

    private saveCurrentDay() {
        this.calendarService.setCurrentDay(this._currentDay);
    }

    private initialiseCurrentWeek() {
        const savedWeek = this.calendarService.getCurrentWeek();
        if (savedWeek && savedWeek.isValid()) {
            this._currentWeek = savedWeek;
        }
        this.saveCurrentDay();
    }

    get currentWeek() {
        return this._currentWeek;
    }

    set currentWeek(value) {
        if (!this._currentWeek.isSame(value, 'week')) {
            this._currentWeek = value;
            this.saveCurrentWeek();
        }
    }

    private saveCurrentWeek() {
        this.calendarService.setCurrentWeek(this._currentWeek);
    }

    private initialiseCurrentMonth() {
        const savedMonth = this.calendarService.getCurrentMonth();
        if (savedMonth) {
            this.date = savedMonth;
        }
        this.saveCurrentMonth();
    }

    private saveCurrentMonth() {
        this.calendarService.setCurrentMonth(this.date);
    }

    async ngAfterViewInit() {
        if(this.field.itemHeight != null) {
            this.itemHeight = this.field.itemHeight;
        }
        this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));

        if (this.field.enableZoomPinchOnMobileTablet == 1) {
            const divElement = this.zoomableDiv.nativeElement;
            divElement.addEventListener('touchstart', this.onTouchStart.bind(this), {passive: false});
            divElement.addEventListener('touchmove', this.onTouchMove.bind(this), {passive: false});
            divElement.addEventListener('touchend', this.onTouchEnd.bind(this));
        }

        if(this.field.automaticallyMakeFullScreenOnLoad == 1) {
            this.fullScreen[this.field.id] = true;
        }

    }

    onTouchStart(event: TouchEvent) {

        if (event.touches.length === 2) {
            // Pinch start
            this.touchStartDistance = this.getDistance(event.touches[0], event.touches[1]);
            this.isDragging = false;
            this.isPinching = true;
            event.preventDefault();
        } else if (event.touches.length === 1) {
            // Drag start
            this.isPinching = false;
            this.lastTouchX = event.touches[0].pageX;
            this.lastTouchY = event.touches[0].pageY;
            this.isDragging = true;
        }
    }

    onTouchMove(event: TouchEvent) {
        if (this.isPinching && event.touches.length === 2) {
            // Pinch move
            const touchMoveDistance = this.getDistance(event.touches[0], event.touches[1]);
            const zoomFactor = touchMoveDistance / this.touchStartDistance;

            // Only call applyScale here, which will calculate and apply the scaling
            this.applyScale(zoomFactor);

            // Update the start distance for the next move event
            this.touchStartDistance = touchMoveDistance;
            event.preventDefault();
        } else if (!this.isPinching && this.isDragging && event.touches.length === 1) {
            // Drag move
            const touch = event.touches[0];
            const deltaX = touch.pageX - this.lastTouchX;
            const deltaY = touch.pageY - this.lastTouchY;

            // Update the current translation based on the drag
            this.currentTranslateX += deltaX;
            this.currentTranslateY += deltaY;

            // Apply the combined translation and scaling transform
            this.applyTransform();

            // Update the last touch position
            this.lastTouchX = touch.pageX;
            this.lastTouchY = touch.pageY;
            event.preventDefault();
        }
    }

    resetZoom() {
        this.scale = 1;
        this.zoomableDiv.nativeElement.style.transform = 'scale(1)';
        if (this.isDragging) {
            // Drag end
            this.isDragging = false;
        }

        this.isPinching = false;
    }


    applyTransform() {
        // Apply both scale and translate as a combined transform
        const transform = `scale(${this.currentScale}) translate(${this.currentTranslateX}px, ${this.currentTranslateY}px)`;
        this.zoomableDiv.nativeElement.style.transform = transform;
    }

    applyScale(zoomFactor: number) {
        // Calculate the scaling factor to reduce sensitivity
        const scalingFactor = 0.5; // Adjust as needed
        // Apply the scaling factor to the zoom factor only once
        this.currentScale *= (1 + (zoomFactor - 1) * scalingFactor);
        // Now apply the updated scale along with the current translation
        this.applyTransform();
    }


    onTouchEnd(event: TouchEvent) {
        if (this.isDragging) {
            // Drag end
            this.isDragging = false;
        }
        if (event.touches.length === 0) {
            this.isPinching = false;
        }
    }

    getDistance(touch1: Touch, touch2: Touch): number {
        const dx = touch1.pageX - touch2.pageX;
        const dy = touch1.pageY - touch2.pageY;
        return Math.sqrt(dx * dx + dy * dy);
    }


    ngDoCheck() {
        const currentValue = this.scanStation?.stationDataForDropDown?.[this.leftHandDataListFieldSlug];
        if (currentValue !== this.previousValue) {
            this.previousValue = currentValue;

            this.changeDetectorRef.detectChanges();
            this.updateStylesOfElements();

        }
    }


    @HostListener('document:dragstart', ['$event'])
    onDragStart(event: any) {
        const resultsBoxAncestor = event.target.closest('.field-type-results-box');
        const fieldIdElement = event.target.closest(`[id^="scanStationForm-${this.field.id}"`);
        if (fieldIdElement != null || resultsBoxAncestor != null) {

            this.changeDetectorRef.detach();
            const target = event.target as Element;
            // we're moving the whole record, the start and finish date
            if (target.hasAttribute('data-cartolytics_entry_row_id') && target.classList.contains('calendar-item')) {
                const draggedValue = event.target.getAttribute('data-cartolytics_entry_row_id');
                let dataTransferObject = {
                    eventAction: "moving-record-within-calendar",
                    value: draggedValue,
                    numberOfDays: 0

                };

                const elements = document.querySelectorAll(`[data-cartolytics_entry_row_id="${draggedValue}"]`);

                dataTransferObject.numberOfDays = elements.length;
                elements[0].classList.add('first-dragged'); // Add a class to the first element
                elements.forEach((element) => {
                    element.classList.add('dragging');
                });
                target.classList.add('currently-dragging');
                this.dataTransferObject = dataTransferObject;
                event.dataTransfer.setData('application/json', JSON.stringify(this.dataTransferObject));

            } else if (target.classList.contains('handle')) {
                // we're moving just the end date
                let dataTransferObject = {
                    eventAction: "changing-end-date",
                    value: null
                };

                const parentElement = target.parentElement as Element;
                const recordId = parentElement.getAttribute('data-cartolytics_entry_row_id');
                if (recordId !== null) {
                    dataTransferObject.value = recordId;
                    this.dataTransferObject = dataTransferObject;
                    event.dataTransfer.setData('application/json', JSON.stringify(this.dataTransferObject));
                }

                target.classList.add('currently-dragging');
            } else if (target.classList.contains('new-record-draggable-value-element')) {
                // we're moving a record to the calendar
                let dataTransferObject = {
                    eventAction: "new-record-to-calendar",
                    value: null
                };

                const recordValue = target.getAttribute('data-value');

                if (recordValue !== null) {
                    dataTransferObject.value = recordValue;
                    this.dataTransferObject = dataTransferObject;
                    event.dataTransfer.setData('application/json', JSON.stringify(this.dataTransferObject));
                }

            }


        }

    }

    @HostListener('document:dragend', ['$event'])
    onDragEnd(event: any) {
        const draggedValue = event.target.getAttribute('data-cartolytics_entry_row_id');
        const elements = document.querySelectorAll(`[data-cartolytics_entry_row_id="${draggedValue}"]`);
        if (elements != null && elements[0] != null) {
            elements[0].classList.remove('first-dragged'); // Remove the class from the first element

            elements.forEach((element) => {
                element.classList.remove('dragging');
            });
        }
    }

    @HostListener('document:dragover', ['$event'])
    onDragOver(event: any) {

        // Prevent the default behavior to allow dropping
        event.preventDefault();
        event.stopPropagation();

        let dataTransferObject = this.dataTransferObject;
        if (dataTransferObject.eventAction == 'moving-record-within-calendar') {

            // Get the element below the cursor
            const elementBelow = document.elementFromPoint(event.clientX, event.clientY);

            // Traverse up the DOM tree to find the closest parent element with the data-formatteddate attribute
            let parentElement = elementBelow.closest('[data-formatteddate]');

            if (parentElement == null) {
                const elementsAtPoint = document.elementsFromPoint(event.clientX, event.clientY);

                for (const element of elementsAtPoint) {
                    if (element.getAttribute('data-formatteddate')) {
                        parentElement = element;
                        break;
                    }

                }
            }
            const currentlyDragging = document.querySelector('.currently-dragging');

            if (parentElement && parentElement.hasAttribute('data-formatteddate') && currentlyDragging != null) {
                const formattedDate = parentElement.getAttribute('data-formatteddate');

                if (formattedDate != null) {
                    // Remove "day-day-dragged-over" class from all elements
                    document.querySelectorAll('.day-dragged-over').forEach(el => el.classList.remove('day-dragged-over'));
                    let dataTransferObject = this.dataTransferObject;
                    const draggedValue = dataTransferObject.value;
                    const elements = document.querySelectorAll(`[data-cartolytics_entry_row_id="${draggedValue}"]`);
                    const draggedElementsCount = elements.length;
                    const currentDraggingPosition = Array.from(elements).indexOf(currentlyDragging);

                    let currentElement = parentElement;
                    // Traverse to the previous elements based on currentDraggingPosition
                    for (let i = 0; i < currentDraggingPosition; i++) {
                        if (currentElement.previousElementSibling) {
                            currentElement = currentElement.previousElementSibling;
                        } else {
                            break;
                        }
                    }
                    const siblings = Array.from(event.target.parentElement.children);
                    const draggedElementIndex = siblings.indexOf(event.target);

                    for (let i = -currentDraggingPosition; i < draggedElementsCount - currentDraggingPosition && currentElement; i++) {
                        currentElement.classList.add('day-dragged-over');
                        currentElement = currentElement.nextElementSibling;
                    }
                }
            }

        } else if (dataTransferObject.eventAction == "new-record-to-calendar") {
            document.querySelectorAll('.day-dragged-over').forEach(el => el.classList.remove('day-dragged-over'));
            const elementBelow = document.elementFromPoint(event.clientX, event.clientY);

            if (elementBelow.classList.contains('calendar-item')) {
                // Work with the element that is the "calendar-item"
                elementBelow.classList.add('day-dragged-over');
            } else {
                let parentElement = elementBelow.closest('[data-formatteddate]');

                const elementsAtPoint = document.elementsFromPoint(event.clientX, event.clientY);

                for (const element of elementsAtPoint) {
                    if (element.getAttribute('data-formatteddate')) {
                        parentElement = element;
                        break;
                    }

                }
                if (parentElement) {
                    parentElement.classList.add('day-dragged-over');
                }
            }

        } else if (dataTransferObject.eventAction == "changing-end-date") {

        }
    }

    @HostListener('document:drop', ['$event'])
    async onDrop(event: any) {

        const fieldIdElement = event.target.closest(`[id^="scanStationForm-${this.field.id}"`);
        if (fieldIdElement != null) {
            this.changeDetectorRef.reattach();
            if (!this.canEdit(this.main)) {
                return false;
            }

            document.querySelectorAll('.position-complete').forEach(el => el.classList.remove('position-complete'));
            // Prevent the default action (open as link for some elements)
            event.preventDefault();
            // Stop the event from propagating up the DOM tree
            event.stopPropagation();

            // Get the target element
            const targetElement = event.target as Element;

            let dataTransferObject = this.dataTransferObject;

            if (dataTransferObject.eventAction == 'changing-end-date') {


                const elementsAtPoint = document.elementsFromPoint(event.clientX, event.clientY);
                let formattedDate = null;


                for (const element of elementsAtPoint) {
                    if (element.getAttribute('data-formatteddate')) {
                        formattedDate = element.getAttribute('data-formatteddate');
                        break;
                    }

                }
                // Handle drop event for '.days li' elements


                if (formattedDate) {
                    let value = dataTransferObject.value;
                    let calendarResourcePlanningViewType: any = false;

                    if(this.field.showSingleDayWithHoursViewOnDateClick == 1 && this.field.calendarResourcePlanningViewType == 'single-day-with-hours') {
                        calendarResourcePlanningViewType = "single-day-with-hours";
                    }

                    await this.calendarService.changeEndDateValueFromCalendar(this.field.id, value, formattedDate, calendarResourcePlanningViewType);

                    await this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
                }


            } else if (dataTransferObject.eventAction == 'moving-record-within-calendar') {
                const elements = document.querySelectorAll('.day-dragged-over');
                let startDate = elements[0].getAttribute('data-formatteddate');


                const elementsAtPoint = document.elementsFromPoint(event.clientX, event.clientY);

                for (const element of elementsAtPoint) {
                    if (element.getAttribute('data-formatteddate')) {
                        startDate = element.getAttribute('data-formatteddate');
                        break;
                    }

                }


                const leftValue = elements[0].getAttribute('data-leftvalue');

                let calendarResourcePlanningViewType: any = false;

                if(this.field.showSingleDayWithHoursViewOnDateClick == 1 && this.field.calendarResourcePlanningViewType == 'single-day-with-hours') {
                    calendarResourcePlanningViewType = "single-day-with-hours";
                }

                await this.calendarService.moveRecordWithinCalendar(this.field.id, dataTransferObject.value, startDate, dataTransferObject.numberOfDays, leftValue, calendarResourcePlanningViewType);
                await this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
            } else if (dataTransferObject.eventAction == 'new-record-to-calendar') {

                const element = document.querySelector('.day-dragged-over');
                let formattedDate = null;

                if (element != null) {
                    formattedDate = element.getAttribute('data-formatteddate');

                }

                let leftValue = null;
                const elementsAtPoint = document.elementsFromPoint(event.clientX, event.clientY);
                for (const element of elementsAtPoint) {
                    if (element.getAttribute('data-formatteddate')) {
                        formattedDate = element.getAttribute('data-formatteddate');
                        leftValue = element.getAttribute('data-leftvalue');
                        break;
                    }

                }


                const cartolyticsEntryRowId = element.getAttribute('data-cartolytics_entry_row_id');
                let extraDataToSend = this.buildExtraDataToSend()

                let calendarResourcePlanningViewType: any = false;

                if(this.field.showSingleDayWithHoursViewOnDateClick == 1 && this.field.calendarResourcePlanningViewType == 'single-day-with-hours') {
                    calendarResourcePlanningViewType = "single-day-with-hours";
                }

                if (formattedDate != null && cartolyticsEntryRowId == null) {


                    await this.calendarService.addNewOrUpdateRecordFromCalendar(this.field.id, formattedDate, dataTransferObject.value, leftValue, extraDataToSend, null, calendarResourcePlanningViewType);
                    await this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
                } else {

                    if (cartolyticsEntryRowId != null) {
                        await this.calendarService.updateRecordValueFromCalendar(this.field.id, cartolyticsEntryRowId, dataTransferObject.value, calendarResourcePlanningViewType)
                        await this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
                    }
                }
            }
            document.querySelectorAll('.day-dragged-over').forEach(el => el.classList.remove('day-dragged-over'));

        }

    }


    constructor(
        @Inject(CalendarResourcePlanningService) private calendarService: CalendarResourcePlanningService,
        @Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(OverlayContainer) private overlayContainer: OverlayContainer,

    ) {
        super();

        this.calendarHighestOffsets = [];

    }

    trackByItemFn(index, item): any {
        return item.id;  // assuming each item has a unique id property
    }

    trackByLeftValueFn(index, leftValue): any {
        console.log('test');
        return leftValue;  // if leftValue is unique
    }

    trackByDayFn(index, day): any {
        return day;  // if day is unique
    }

    trackByHourFn(index, hour): any {
        return hour;  // if hour is unique
    }

    renderCalendar = async () => {

        if (this.field.calendarResourcePlanningViewType == 'month-calendar-view' || this.field.calendarResourcePlanningViewType == 'vertical-month-view') {
            this.date.setDate(1);
            this.lastDay = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate();
            this.prevLastDay = new Date(this.date.getFullYear(), this.date.getMonth(), 0).getDate();

            this.firstDayIndex = this.date.getDay();
            if (this.firstDayIndex === 0) {
                this.firstDayIndex = 6;
            } else {
                this.firstDayIndex -= 1;
            }

            this.lastDayIndex = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDay();
            this.nextDays = 7 - this.lastDayIndex - 1;
            if (this.lastDayIndex === 0) {
                this.nextDays = 6;
            } else {
                this.nextDays -= 1;
            }

            this.days = [];

// Previous month days
            let prevMonthDate = new Date(this.date.getFullYear(), this.date.getMonth() - 1, 1);
            for (let x = this.firstDayIndex; x > 0; x--) {
                const day = this.prevLastDay - x + 1;
                const dateString = moment(prevMonthDate).set('date', day).format('YYYY-MM-DD 00:00:00');
                const dateStringAlternative = moment(prevMonthDate).set('date', day).format('dddd DD/MM');
                this.days.push([day, dateString, dateStringAlternative]);
            }

// Current month days
            for (let i = 1; i <= this.lastDay; i++) {
                const dateString = moment(this.date).set('date', i).format('YYYY-MM-DD 00:00:00');
                const dateStringAlternative = moment(this.date).set('date', i).format('dddd DD/MM');
                this.days.push([i, dateString, dateStringAlternative]);
            }

// Next month days
            let nextMonthDate = new Date(this.date.getFullYear(), this.date.getMonth() + 1, 1);
            for (let j = 1; j <= this.nextDays; j++) {
                const dateString = moment(nextMonthDate).set('date', j).format('YYYY-MM-DD 00:00:00');
                const dateStringAlternative = moment(nextMonthDate).set('date', j).format('dddd DD/MM');
                this.days.push([j, dateString, dateStringAlternative]);
            }

        } else if (this.field.calendarResourcePlanningViewType == 'resource-planning-view-with-hours' || this.field.calendarResourcePlanningViewType == 'resource-planning-view') {
            this.leftHandDataListFieldSlug = this.scanStation.scanStationObj.scanStationFieldIdsToField[this.field.localDataListFieldId].field_slug
            this.days = [];
            for (let i = 0; i < 7; i++) {
                const dateString = moment(this.currentWeek).add(i, 'days').format('YYYY-MM-DD 00:00:00');
                this.days.push([i, dateString]);
            }
        } else if (this.field.calendarResourcePlanningViewType == 'resource-planning-view-with-hours-single-day') {
            this.leftHandDataListFieldSlug = this.scanStation.scanStationObj.scanStationFieldIdsToField[this.field.localDataListFieldId].field_slug
            this.days = [];
            const dateString = moment(this.currentDay).format('YYYY-MM-DD 00:00:00');
            this.days.push([0, dateString]);
        }

        this.changeDetectorRef.detectChanges();
        this.updateStylesOfElements();
    }

    getFormattedDateWithHour(hour, formattedDate) {
        let dateWithHour = moment(formattedDate).hour(hour).format('YYYY-MM-DD HH:mm:ss');
        return dateWithHour;
    }

    getFormattedDateWithHourNiceTime(hour, formattedDate) {
        let dateWithHour = moment(formattedDate).hour(hour).format('hh:mm a');
        return dateWithHour;
    }

    prevDay() {
        this.currentDay = moment(this.currentDay).subtract(1, 'days');
        this.dateForCalendarData = this.dateForCalendarData.subtract(1, 'days');
        this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
        this.resetZoom()

    }

    nextDay() {
        this.currentDay = moment(this.currentDay).add(1, 'days');
        this.dateForCalendarData = this.dateForCalendarData.add(1, 'days');
        this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
        this.resetZoom()

    }

    prevWeek() {
        this.currentWeek = moment(this.currentWeek).subtract(1, 'weeks');
        this.dateForCalendarData = this.dateForCalendarData.subtract(1, 'weeks');
        this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
        this.resetZoom()

    }

    nextWeek() {
        this.currentWeek = moment(this.currentWeek).add(1, 'weeks');
        this.dateForCalendarData = this.dateForCalendarData.add(1, 'weeks');
        this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
        this.resetZoom()
    }


    prevMonth() {
        this.date.setMonth(this.date.getMonth() - 1);
        this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
        this.saveCurrentMonth()
        this.dateForCalendarData = this.dateForCalendarData.subtract(1, 'months');
        this.resetZoom()

    }

    nextMonth() {
        this.dateForCalendarData = this.dateForCalendarData.add(1, 'months');
        this.date.setMonth(this.date.getMonth() + 1);
        this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
        this.saveCurrentMonth();
        this.resetZoom()

    }


    getDataForCalendarField = async (dateForCalendarData) => {

        let calendarResourcePlanningViewType: any = false;

        if(this.field.showSingleDayWithHoursViewOnDateClick == 1 && this.field.calendarResourcePlanningViewType == 'single-day-with-hours') {
            calendarResourcePlanningViewType = "single-day-with-hours";
        }

        if (this.scanStation.scanStationObj.stateName == 'edit-bloc') {
            return true;
        }

        let dataFilteringData = {
            stationID: '',
            filterDataToQuery: {}
        }

        if (this.field.filterDataLookUp != null && Object.values(this.field.filterDataLookUp).length > 0) {
            dataFilteringData.stationID = this.field.calendarAreaBlocDetails.stationId;
            let filterDataToQueryObject = {};
            for (let i = 0; i < Object.values(this.field.filterDataLookUp).length; i++) {
                let localFieldSlug = this.scanStation.scanStationObj.scanStationFieldIdsToField[this.field.filterDataLookUp[i].localFieldId].field_slug;

                filterDataToQueryObject[this.field.filterDataLookUp[i].remoteFieldId] = [this.scanStation.scanStationObj.dataToAdd[localFieldSlug]];
            }

            dataFilteringData.filterDataToQuery = filterDataToQueryObject;

        }

        let calendarData = await this.calendarService.getDataForCalendarField(this.field.id, dateForCalendarData, dataFilteringData, calendarResourcePlanningViewType);
        if (this.calendarDataItemStartDates == null) {
            this.calendarDataItemStartDates = {};
        }

        if (this.calendarDataItemContinuedDates == null) {
            this.calendarDataItemContinuedDates = {};
        }

        if (this.calendarItemCounts == null) {
            this.calendarItemCounts = {};
        }

        this.calendarDataItemStartDates[this.field.id] = calendarData.itemStartDates;
        this.calendarDataItemContinuedDates[this.field.id] = calendarData.calendarDataItemContinuedDates;
        this.linkedBlocFieldNames[this.field.id] = calendarData.linkedBlocFieldNames;
        this.linkedBlocFieldSlugs[this.field.id] = calendarData.linkedBlocFieldSlugs;
        this.startDateHasTimeFormats[this.field.id] = calendarData.startDateHasTimeFormats;
        this.endDateHasTimeFormats[this.field.id] = calendarData.endDateHasTimeFormats;
        this.calendarItemCounts[this.field.id] = calendarData.calendarItemCounts;

        await this.renderCalendar();
        this.changeDetectorRef.markForCheck();
        this.resetZoom()

    }

    filterByLeftHandValue(items: any[], value: any): any[] {
        if (items != null && items.length > 0) {
            let fieldSlugToFilter = items[0].blcx_calendar_left_side_matching_field_slug;
            return items.filter(item => item[fieldSlugToFilter] === value);
        } else {
            return items;
        }
        if (!items || value === undefined) {
            return items;
        }
    }

    setPositionOfItem(cartolytics_entry_row_id: number) {
        const element = document.querySelector('.item-started[data-cartolytics_entry_row_id="' + cartolytics_entry_row_id + '"]');
        if (element) {
            const rect = element.getBoundingClientRect();
            const parentRect = element.parentElement.getBoundingClientRect();
            const relativeTop = rect.top - parentRect.top;
            const relativeLeft = rect.left - parentRect.left;

            const styles = {
                'top': relativeTop + 'px', // For example, set the color to red
                'position': 'relative', // Set the font size to 18px
                // Add any other styles as needed
            };

            return styles;

        }
    }

    isElementOverlapping(element: HTMLElement, className: string, cartolytics_entry_row_id) {
        const rect1 = element.getBoundingClientRect();


        const elements = document.querySelectorAll('#scanStationForm-' + this.field.id + ' ' + className);

        for (let i = 0; i < elements.length; i++) {
            const otherElement = elements[i] as HTMLElement;
            if (element === otherElement) {
                // Skip comparison against itself
                continue;
            }


            let thisElementsValue = element.parentElement.parentElement.getAttribute('data-leftvalue');

            let otherElementsValue = otherElement.parentElement.parentElement.getAttribute('data-leftvalue');

            let usingWeekNumber = false;
            if (thisElementsValue == null || otherElementsValue == null) {
                thisElementsValue = element.parentElement.parentElement.getAttribute('data-weeknumber');
                otherElementsValue = otherElement.parentElement.parentElement.getAttribute('data-weeknumber');
                if (thisElementsValue != null) {

                    usingWeekNumber = true;

                }
            }

            // we're only checking elements in the same left hand value row
            if (thisElementsValue != otherElementsValue) {

                if (usingWeekNumber == false) {

                    continue;

                }
            }


            const rect2 = otherElement.getBoundingClientRect();

            const overlap = !(rect1.right < rect2.left ||
                rect1.left > rect2.right ||
                rect1.bottom < rect2.top ||
                rect1.top > rect2.bottom);

            if (overlap) {

                return otherElement;
            }
        }

        return null;
    }

    updateStylesOfElements(i = 0) {
        let rerurnQuery = false;
        for (let dateItem in this.calendarDataItemStartDates[this.field.id]) {
            let items = this.calendarDataItemStartDates[this.field.id][dateItem];
            for (let j = 0; j < items.length; j++) {
                let item = items[j];
                let calendarFullyUpdated = this.setWidthFromNumberOfHours(item, j, 'startedItems', dateItem);
                if (calendarFullyUpdated == 1) {
                    rerurnQuery = true;
                }
            }
        }

        for (let continuedDateItem in this.calendarDataItemContinuedDates[this.field.id]) {

            let continuedItems = this.calendarDataItemContinuedDates[this.field.id][continuedDateItem];

            for (let k = 0; k < continuedItems.length; k++) {
                let continuedItem = continuedItems[k];

                let calendarFullyUpdated = this.setWidthFromNumberOfHours(continuedItem, k, 'continuedItems', continuedDateItem);
                if (calendarFullyUpdated == 1) {
                    rerurnQuery = true;
                }
            }

        }

        if (rerurnQuery == true) {

            i++;
            // stop infinite loop
            if (i < 20) {
                this.updateStylesOfElements(i);
            }
        }

    }


    setWidthFromNumberOfHours(item, itemIndex, source, dateItem) {

        let element = null;
        if (source == 'startedItems') {
            element = document.querySelector(`#scanStationForm-${this.field.id} .item-started[data-cartolytics_entry_row_id="${item.cartolytics_entry_row_id}"]`) as HTMLElement;
        } else {
            element = document.querySelector(`#scanStationForm-${this.field.id} .item-continued[data-cartolytics_entry_row_id="${item.cartolytics_entry_row_id}"]`) as HTMLElement;

        }


        if (element != null) {

            let overlappingElement = this.isElementOverlapping(element, '.calendar-item', item.cartolytics_entry_row_id);
            if (overlappingElement != null) {

                let thisElementsFormattedDate: string | null = null;
                let formattedDate: string | null = null;
                let thisElementsParent = element.parentElement;
                if (thisElementsParent) {
                    let thisElementsgrandParent = thisElementsParent.parentElement;
                    if (thisElementsgrandParent) {
                        thisElementsFormattedDate = thisElementsgrandParent.getAttribute('data-formatteddate');

                    }
                }


                let parentElement = overlappingElement.parentElement;
                if (parentElement) {
                    let grandParentElement = parentElement.parentElement;
                    if (grandParentElement) {
                        formattedDate = grandParentElement.getAttribute('data-formatteddate');

                    }
                }

                if (thisElementsFormattedDate != null && formattedDate != null) {

                    let thisElementsRecordId = '0';
                    let overlappingElementsRecordId = '0';

                    if (thisElementsFormattedDate == formattedDate) {

                        thisElementsRecordId = element.getAttribute('data-cartolytics_entry_row_id');
                        overlappingElementsRecordId = overlappingElement.getAttribute('data-cartolytics_entry_row_id');
                    }


                    let moment1 = moment(thisElementsFormattedDate, "YYYY-MM-DD HH:mm:ss");


                    let moment2 = moment(formattedDate, "YYYY-MM-DD HH:mm:ss");

                    let parsedValue = parseInt(thisElementsRecordId, 10);


                    let thisElementsRecordIdInt = parseInt(thisElementsRecordId, 10);
                    let overlappingElementsRecordIdInt = parseInt(overlappingElementsRecordId, 10);


                    if (moment1.isAfter(moment2) || thisElementsRecordIdInt > overlappingElementsRecordIdInt) {


                        const rect = overlappingElement.getBoundingClientRect();
                        const parentElementDiv = overlappingElement.parentElement;
                        const parentRect = parentElementDiv.getBoundingClientRect();


                        const relativeTop = rect.top - parentRect.top;


                        const divWithLeftValue = parentElementDiv.parentElement;
                        let leftValue = divWithLeftValue.getAttribute('data-leftvalue');
                        if (leftValue == null) {
                            leftValue = divWithLeftValue.getAttribute('data-weeknumber');
                        }


                        if (this.calendarHighestOffsets[leftValue] == null) {
                            this.calendarHighestOffsets[leftValue] = 32 + (this.itemHeight-29);
                        }

                        if ((relativeTop + 32 + (this.itemHeight-29)) >= this.calendarHighestOffsets[leftValue]) {
                            this.calendarHighestOffsets[leftValue] = relativeTop + 64 + (this.itemHeight-29);
                        }


                        if (source == 'continuedItems') {
                            this.calendarDataItemContinuedDates[this.field.id][dateItem][itemIndex].topPosition = relativeTop + 64 + (this.itemHeight-29)
                        }

                        if (source == 'startedItems') {
                            this.calendarDataItemStartDates[this.field.id][dateItem][itemIndex].topPosition = relativeTop + 32 + (this.itemHeight-29)
                        }

                        //element.style.top = (relativeTop + 40) + 'px';
                        this.changeDetectorRef.detectChanges();


                        return 1;
                    }

                }

            }

            return 0;

        }

    }

    getWeeks(): any[][] {
        let weeks = [];
        for (let i = 0; i < this.days.length; i += 7) {
            weeks.push(this.days.slice(i, i + 7));
        }
        return weeks;
    }

    isCurrentWeek(week: any[]): boolean {
        const today = moment().locale('en-gb');
        return week.some(day => moment(day[1]).isSame(today, 'day'));
    }

    getWeekInMonth(index) {
        return 'week-' + Math.floor(index / 7);
    }

    getWidthForCalendarItem(item): number {

        let width = 130
        const elements = document.querySelectorAll(`#scanStationForm-${this.field.id} .month-calendar-view .days li`);
        if (elements.length > 0) {
            const firstElement = elements[0] as HTMLElement;
            width = firstElement.offsetWidth;

        }
        if (item.blcwrx_no_of_days != null) {
            width = width * (item.blcwrx_no_of_days)
        }

        if (item.blcwrx_no_of_days_remaining != null) {
            width = width * (item.blcwrx_no_of_days_remaining)
        }
        // prevents bug where the system thought they were overlapping so were pushing them down even if on the next day
        width = width - 1

        return width;


    }

    returnToOriginalCalendar() {
        this.field.calendarResourcePlanningViewType = this.originalCalendarResourcePlanningViewType;
        this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
    }

    /**
     * This opens up a pop up to add a new record
     */
    openCreateNewEntryPopUp(formattedDate, leftValue = false): void {

        // if the user has chosen the pop up option then show the pop up instead of the calendar default pop up and it allows editing
        if(this.field.showBlocPopUpOnEntryClickForAddingRecords == 1) {
            this.scanStation.gridPopUpObject = {};
            this.scanStation.gridPopUpObject.gridPopUpTriggerSource =  'add-new-record-from-calendar';
            this.scanStation.gridPopUpObject.sourceField = this.field;
            this.scanStation.gridPopUpObject.popUpOrEmbedded = 'popup';
            this.scanStation.openRemoteScanStationPopUp(this.field.calendarAreaBlocDetails.stationId, null, this.scanStation.gridPopUpObject.gridPopUpTriggerSource, null);
            return;
        }

        if(this.field.showSingleDayWithHoursViewOnDateClick == 1 && this.field.calendarResourcePlanningViewType != 'single-day-with-hours') {
            this.originalCalendarResourcePlanningViewType = this.field.calendarResourcePlanningViewType;
            this.field.calendarResourcePlanningViewType = 'single-day-with-hours';
            this.getDataForCalendarField(formattedDate);
            this.currentDay = moment(formattedDate, "YYYY-MM-DD HH:mm:ss").locale('en-gb');
            return;
        }

        this.openDialogsCount++;
        let extraDataToSend = this.buildExtraDataToSend();

        let leftValueList = [];

        if (this.scanStation.stationDataForDropDown[this.leftHandDataListFieldSlug] != null) {
            leftValueList = this.scanStation.stationDataForDropDown[this.leftHandDataListFieldSlug];
        }

        const dialogRef = this.dialog.open(AddNewRecordComponent, {
            data: {
                fieldId: this.field.id,
                leftValue: leftValue,
                extraDataToSend: extraDataToSend,
                calendarFieldName: this.field.field_name,
                formattedDate: formattedDate,
                linkedBlocFieldNames: this.linkedBlocFieldNames,
                linkedBlocFieldSlugs: this.linkedBlocFieldSlugs,
                field: this.field,
                leftValueList: leftValueList,
                startDateHasTimeFormats: this.startDateHasTimeFormats,
                endDateHasTimeFormats: this.endDateHasTimeFormats

            }
        });

        this.overlayContainer.getContainerElement().classList.add('blocworx-popup');

        dialogRef.afterClosed().subscribe(result => {
            this.openDialogsCount--;
            if (this.openDialogsCount <= 0) {
                this.overlayContainer.getContainerElement().classList.remove('blocworx-popup');
                this.openDialogsCount = 0;
            }

            this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
        });
    }


    /**
     * This opens up a pop up for editing records
     */
    editEntryPopUp(event: Event, item, leftValue = false): void {

        this.openDialogsCount++;
        if (event) {
            event.stopPropagation();
        }

        // if the user has chosen the pop up option then show the pop up instead of the calendar default pop up, for viewing only
        if(this.field.showBlocPopUpOnEntryClick == 1 && this.field.showBlocPopUpOnEntryClickAllowEdit != 1) {
            this.scanStation.gridPopUpObject = {};
            this.scanStation.gridPopUpObject.gridPopUpTriggerSource =  'showing-single-record';
            this.scanStation.gridPopUpObject.sourceField = this.field;
            this.scanStation.gridPopUpObject.popUpOrEmbedded = 'popup';
            this.scanStation.openRemoteScanStationPopUp(this.field.calendarAreaBlocDetails.stationId, item.cartolytics_entry_row_id, this.scanStation.gridPopUpObject.gridPopUpTriggerSource, null);
            return;
        }

        // if the user has chosen the pop up option then show the pop up instead of the calendar default pop up and it allows editing
        if(this.field.showBlocPopUpOnEntryClickAllowEdit == 1) {
            this.scanStation.gridPopUpObject = {};
            this.scanStation.gridPopUpObject.gridPopUpTriggerSource =  'editing-record-from-calendar';
            this.scanStation.gridPopUpObject.sourceField = this.field;
            this.scanStation.gridPopUpObject.popUpOrEmbedded = 'popup';
            this.scanStation.openRemoteScanStationPopUp(this.field.calendarAreaBlocDetails.stationId, item.cartolytics_entry_row_id, this.scanStation.gridPopUpObject.gridPopUpTriggerSource, null);
            return;
        }

        let leftValueList = [];

        if (this.scanStation.stationDataForDropDown[this.leftHandDataListFieldSlug] != null) {
            leftValueList = this.scanStation.stationDataForDropDown[this.leftHandDataListFieldSlug];
        }

        let extraDataToSend = this.buildExtraDataToSend();

        const dialogRef = this.dialog.open(EditRecordComponent, {
            data: {
                fieldId: this.field.id,
                leftValue: leftValue,
                extraDataToSend: extraDataToSend,
                calendarFieldName: this.field.field_name,
                linkedBlocFieldNames: this.linkedBlocFieldNames,
                linkedBlocFieldSlugs: this.linkedBlocFieldSlugs,
                field: this.field,
                leftValueList: leftValueList,
                item: item,
                startDateHasTimeFormats: this.startDateHasTimeFormats,
                endDateHasTimeFormats: this.endDateHasTimeFormats,
                canEdit: this.canEdit(this.main)
            }
        });

        this.overlayContainer.getContainerElement().classList.add('blocworx-popup');

        dialogRef.afterClosed().subscribe(result => {
            this.openDialogsCount--;
            if (this.openDialogsCount <= 0) {
                this.overlayContainer.getContainerElement().classList.remove('blocworx-popup');
                this.openDialogsCount = 0;
            }
            this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
        });
    }

    /**
     * Open a pop up dialog to show counted data for a specific date and time.
     *
     * @param {Event} event - The event that triggered the method.
     * @param {string} dataInTime - The date and time for which to show the counted data.
     * @returns {void}
     */
    showCountedDataPopUp(event: Event, dataInTime): void {


        if (event) {
            event.stopPropagation();
        }

        if(this.field.showSingleDayWithHoursViewOnDateClick == 1 && this.field.calendarResourcePlanningViewType != 'single-day-with-hours') {
            this.originalCalendarResourcePlanningViewType = this.field.calendarResourcePlanningViewType;
            this.field.calendarResourcePlanningViewType = 'single-day-with-hours';
            this.getDataForCalendarField(dataInTime);
            this.currentDay = moment(dataInTime, "YYYY-MM-DD HH:mm:ss").locale('en-gb');
            return;
        }

        this.openDialogsCount++;

        let countedDataStartDateRecords = [];

        if (this.calendarDataItemStartDates[this.field.id] != null && this.calendarDataItemStartDates[this.field.id][dataInTime] != null) {
            countedDataStartDateRecords = this.calendarDataItemStartDates[this.field.id][dataInTime]
        }

        let countedDataContinuedDateRecords = [];
        if (this.calendarDataItemContinuedDates[this.field.id] != null && this.calendarDataItemContinuedDates[this.field.id][dataInTime] != null) {
            countedDataContinuedDateRecords = this.calendarDataItemContinuedDates[this.field.id][dataInTime]
        }

        const dialogRef = this.dialog.open(ShowCountedDataPopup, {
            data: {
                countedDataStartDateRecords: countedDataStartDateRecords,
                countedDataContinuedDateRecords: countedDataContinuedDateRecords,
                fieldId: this.field.id,
                editEntryPopUp: this.editEntryPopUp.bind(this)
            }
        });

        this.overlayContainer.getContainerElement().classList.add('blocworx-popup');

        dialogRef.afterClosed().subscribe(result => {

            this.openDialogsCount--;
            if (this.openDialogsCount <= 0) {
                this.overlayContainer.getContainerElement().classList.remove('blocworx-popup');
                this.openDialogsCount = 0;
            }

            this.getDataForCalendarField(this.dateForCalendarData.format('YYYY-MM-DD HH:mm:ss'));
        });
    }

    /**
     * This method builds extra data to send based on matching fields.
     * @return {object} The extra data to send.
     */
    buildExtraDataToSend(): object {
        let extraDataToSend = {};
        if (this.field.sendExtraDataMatchingFields != null) {
            for (let i = 0; i < Object.values(this.field.sendExtraDataMatchingFields).length; i++) {
                let localFieldSlug = this.scanStation.scanStationObj.scanStationFieldIdsToField[this.field.sendExtraDataMatchingFields[i].localFieldId].field_slug;
                extraDataToSend[localFieldSlug] = this.scanStation.scanStationObj.dataToAdd[localFieldSlug];
            }
        }

        return extraDataToSend;
    }

}
