import {Component, Inject, Input, Injectable} from '@angular/core';
import {FieldEditingService} from "@/angular-blocworx/components/fields/fieldEditing/field-editing.service";
import {BaseFieldsComponent} from "@/angular-blocworx/components/fields/BaseFieldsComponent";
import {ResultsBoxService} from "@/angular-blocworx/components/fields/results-box/results-box.service";
import {of} from "rxjs";

@Component({
    selector: 'results-box-create-edit-popup',
    templateUrl: 'angular-blocworx/components/fields/results-box/templates/create-edit/popup.html',
    styleUrls: ['angular-blocworx/components/fields/results-box/results-box.component.scss'],
})

export class ResultsBoxCreateEditPopup extends BaseFieldsComponent {

    @Input() fc;
    @Input() scanStation;
    resultsBoxBlocList: []
    resultsBoxBlocFieldList: []
    resultsBoxBlocFieldList_byFieldId: [];
    fcInitialFieldParameterArrays: Record<string, any[]> = {};
    localFieldMatchingFieldPairsLocalSelectedFieldId: string;
    localFieldMatchingFieldPairsRemoteSelectedFieldId: string;
    selectedResultsBoxOnlyApplyFieldActionsFieldId: string;
    selectedResultsBoxOnlyShowFieldId: string
    selectedFieldToRefreshAfterCompletionId: string;
    chosenValue: string;
    selectedColourForValue: string;


    constructor(
        @Inject(ResultsBoxService) private resultsBoxService: ResultsBoxService,
        @Inject(FieldEditingService) private fieldEditingService: FieldEditingService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.scanStation.activeField = 'results-box'
        await this.initialiseFieldParametersFromValues()
        let job = {
            "id": this.scanStation.scanStationObj.jobID,
            "title": ""
        };

        this.setModuleToLoad('resultsBoxBlocList', 'resultsBoxStationAndFields', job, true);
    }

    async initialiseFieldParametersFromValues(): Promise<void> {
        this.scanStation.externalJobs = {};

        const defaultFieldParameterObject: object = this.resultsBoxService.getInitialParams();

        this.fc.initialFieldParams = this.resultsBoxService.initiateAllFieldParametersFromValues(this.fc.initialFieldParams, defaultFieldParameterObject);

        this.fcInitialFieldParameterArrays['localFieldMatchingFieldPairs'] = Object.values(this.fc.initialFieldParams['localFieldMatchingFieldPairs']);


        this.forceArrayFromObjectOrArray('onlyApplyFieldActionsFieldArray');
        this.forceArrayFromObjectOrArray('onlyShowFieldArray');
        this.forceArrayFromObjectOrArray('valueBasedCellColours')
        this.forceArrayFromObjectOrArray('fieldsToRefreshAfterCompletion')

        if(this.fc.initialFieldParams.resultsBoxStationAndFields.stationId != "") {
            await this.getFieldsFromBlocId('resultsBoxBlocFieldList', this.fc.initialFieldParams.resultsBoxStationAndFields.stationId)
        }
    }

    forceArrayFromObjectOrArray = (fieldParameter) => {
        if(Array.isArray(this.fc.initialFieldParams[fieldParameter])) {
            this.fcInitialFieldParameterArrays[fieldParameter] = this.fc.initialFieldParams[fieldParameter];
        } else {
            this.fcInitialFieldParameterArrays[fieldParameter] = Object.values(this.fc.initialFieldParams[fieldParameter]);
        }
    }

    async getFieldsFromBlocId(parameterFieldList, blocId) {

        let fieldList = await this.fieldEditingService.getFieldsForBlocIdForFieldEditing(blocId);

        if (fieldList.scanStationFields != null) {
            this[parameterFieldList] = fieldList.scanStationFields;
        }

        this[parameterFieldList + '_byFieldId'] = this[parameterFieldList].reduce((acc, cur) => {
            acc[cur.id] = cur;
            return acc;
        }, {});

    }

    async setModuleToLoad(blocListName, moduleFieldParameter, job, secondInstanceOfDirective = false) {

        if (this.fc.initialFieldParams[moduleFieldParameter] == null) {
            this.fc.initialFieldParams[moduleFieldParameter] = {
                "jobId": job.id
            };
        } else {
            this.fc.initialFieldParams[moduleFieldParameter].jobId = job.id;
        }

        let blocListRequest = await this.fieldEditingService.getBlocsFromModuleSelection(moduleFieldParameter, this.fc.initialFieldParams, this.fc.currentJobId);
        if (blocListRequest.data != null) {
            this[blocListName] = blocListRequest.data;
        }

        if (secondInstanceOfDirective == false) {
            this.scanStation.externalJobs = {};
            this.scanStation.externalJobKeyWord = job.title
        } else {
            this.scanStation.externalJobsTwo = {};
            this.scanStation.externalJobKeyWordTwo = job.title
        }
    }

    addOrRemoveLocalAndRemoteMatchingFieldPairs(fieldParameter, action, localFieldId, remoteFieldId, index = null) {
        this.fc.initialFieldParams[fieldParameter] = this.fieldEditingService.addOrRemoveLocalAndRemoteMatchingFieldPairs(this.fc.initialFieldParams[fieldParameter], action, localFieldId, remoteFieldId, index);
        this.fcInitialFieldParameterArrays[fieldParameter] = Object.values(this.fc.initialFieldParams[fieldParameter]);
    }


    convertObjectToArray(object: object): any[] {
        if (object != null) {
            return Object.values(object);
        }
    }

    async ngAfterViewInit(): Promise<void> {

    }

    applyingFieldActions = () => {
        if (
            this.fc.initialFieldParams.resultsBoxEditorApplyBlocInitialisation == 1 ||
            this.fc.initialFieldParams.resultsBoxApplyFieldActions == 1 ||
            this.fc.initialFieldParams.resultsBoxPreSubmitFeatures == 1)
        {
            return true;

        } else {
            return false;
        }
    }

    addSingleFieldList = (fieldParameter, fieldId) => {
        this.fc.initialFieldParams[fieldParameter] = this.fieldEditingService.addSingleFieldList(this.fc.initialFieldParams[fieldParameter], fieldId);
        this.forceArrayFromObjectOrArray(fieldParameter);
    }

    removeFromSingleFieldList = (fieldParameter, index) => {
        this.fc.initialFieldParams[fieldParameter] = this.fieldEditingService.removeFromFieldList(this.fc.initialFieldParams[fieldParameter],index);
        this.forceArrayFromObjectOrArray(fieldParameter);
    }

    /**
     * This function checks if its a field type that might get refreshed when something else happens in the bloc
     * @param field
     */
    isRefreshableListTypeField = (field) => {
        return (field.field_type == 'dropdown-from-station' || field.field_type == 'radio-buttons-from-station'
            || field.field_type == 'autocomplete-field' || field.field_type == 'checkbox-buttons-from-station' || field.autocomplete == 1
            || field.field_type == 'results-box');
    }

    addValueColourCombination = (value, colour) => {
        this.fc.initialFieldParams['valueBasedCellColours'] =this.resultsBoxService.addValueColourCombination(this.fc.initialFieldParams['valueBasedCellColours'], value, colour);
        this.forceArrayFromObjectOrArray('valueBasedCellColours');
    }
    removeValueColourCombination = (index) => {
        this.fc.initialFieldParams['valueBasedCellColours'] = this.resultsBoxService.removeValueColourCombination(this.fc.initialFieldParams['valueBasedCellColours'], index);
        this.forceArrayFromObjectOrArray('valueBasedCellColours');
    }

    resetListsRelatedToSelectBloc = () => {
        this.fc.initialFieldParams['localFieldMatchingFieldPairs'] = {};
        this.fcInitialFieldParameterArrays['localFieldMatchingFieldPairs'] = [];

        this.localFieldMatchingFieldPairsRemoteSelectedFieldId = null;
        this.fc.initialFieldParams.resultsBoxStationAndFields.sortByFieldId = null;
        this.selectedResultsBoxOnlyShowFieldId = null;
        this.selectedResultsBoxOnlyApplyFieldActionsFieldId = null;

        this.fc.initialFieldParams['onlyApplyFieldActionsFieldArray'] = [];
        this.fcInitialFieldParameterArrays['onlyApplyFieldActionsFieldArray'] = [];

        this.fc.initialFieldParams['onlyShowFieldArray'] = [];
        this.fcInitialFieldParameterArrays['onlyShowFieldArray'] = [];

    }

}
